<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add New Menu
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the menu."
                required
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fas', 'save']"
            type="submit"
            success
            @click="submitForm"
          >
            Add Menu
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { map, cloneDeep } from 'lodash' // eslint-disable-line no-unused-vars
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: true,
      form: {},
      defaults: {
        name: ''
      },
      error: {
        errors: {}
      }
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()
      this.$api.post('menus', this.form)
        .then((res) => {
          this.$router.push({
            name: 'menus.single',
            params: {
              model: res.data.id
            }
          })
        })
        .catch((error) => {
          this.error = error
        })
    }
  }
}
</script>
